import React, { useContext, useRef } from "react";
import pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import qrCode from "../assets/images/qr-code.png";
import { hexToRgb, rgbToHex } from "../utils/colorUtils";


const CanvasContext = React.createContext();

export const CanvasProvider = ({ children }) => {

  const canvasRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const leftPaneCanvasRef = useRef(null);
  const pdfCanvasRef = useRef(null);
  const contextRef = useRef(null);
  const qrCanvasRef = useRef(null);

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  let rects = [],
    canvas = null,
    leftPane = null,
    previewCanvas = null,
    pdfCanvas = null,
    mouseX,
    mouseY,
    closeEnough = 10,
    dragok = false,
    ctx = null;
  let startX = 0;
  let startY = 0;

  let x = 0;
  let y = 0;

  let cpiFontSize = 16;
  let cpnFontSize = 16;
  let additionalParamsFontSize = 16;

  let cpnTextAlignment = 'Left';

  let cpiFontColor = {
    r: 0,
    g: 0,
    b: 0,
    hex: "#111",
  };

  let cpnFontColor = {
    r: 0,
    g: 0,
    b: 0,
    hex: "#111",
  };

  let additionalParamsColor = {
    r: 0,
    g: 0,
    b: 0,
    hex: "#111",
  };

  const rectHandleColor = "#00FF00";
  const fontName = "roboto"

  const QR_CODE = 1;
  const CPI = 2;
  const CPN = 3;
  const PLACEHOLDER = 4;
  const ADDITIONAL_PARAM = 5;

  // Allow single digit for additional params
  const SINGLE_DIGIT_TYPE = 6;
  
  // Allow store Id to be individual boxes
  const STORE_ID = 6;
  const STORE_LENGTH = 10;
  const STORE_ID_TITLE = "Store ID"

  let row = 0;

  let CPI_WIDTH = 40;
  let CPI_HEIGHT = 40;

  let lastYCPI = 0;
  let lastYStoreId = 0;

  let cpiLength = 7;
  let cpnLength = 25;

  let filePath = null;
  let placeholders = [];

  let canvasObjects = [];

  let helpLines = [];

  let validations = null;

  let additionalData = [];
  let removedFields = [];
  let newlyAddedFields = [];

  let requiresUserInputOnlyFields = [];

  let showCPN = false;
  let showStoreId = false;
  let drawStoreId = false; 

  // template and newCPILength used for edit 
  const prepareCanvas = (template = null) => {
    canvas = canvasRef.current;

    leftPane = leftPaneCanvasRef.current;

    canvas.width = window.innerWidth / 2 + 300;
    canvas.height = window.innerHeight + 200;
    leftPane.height = window.innerHeight + 200;

    ctx = canvas.getContext("2d");

    contextRef.current = ctx;
    pdfCanvas = pdfCanvasRef.current;

    pdfCanvas.width = window.innerWidth / 2;
    pdfCanvas.height = window.innerHeight + 200;

    rects = [];
    placeholders = [];
    canvasObjects = [];
    helpLines = [];
    requiresUserInputOnlyFields = [];

    // if it is edit flow
    if (template !== null) {
      getBinaryData(template);
    } else {
      drawInstructions();
    }
  };

  const drawInstructions = () => {
    const canvas = pdfCanvasRef.current;

    const pdfCTX = canvas.getContext("2d");

    pdfCTX.fillStyle = "lightgrey";
    // clear the canvas
    pdfCTX.fillRect(0, 0, canvas.width, canvas.height);
    pdfCTX.font = `bold 28px ${fontName}`;
    pdfCTX.textAlign = "center";
    pdfCTX.fillStyle = "grey";
    pdfCTX.fillText(
      "Import PDF to get started",
      canvas.width / 2,
      canvas.height / 2
    );
  };

  const getBinaryData = (template) => {
    // body...
    var xhr = new XMLHttpRequest();
    filePath = template.path;
    xhr.open('GET', template.pdfTemplatePath, true);
    xhr.responseType = 'arraybuffer';
    xhr.setRequestHeader('Accept', '*/*');
    xhr.onload = function (e) {
      //binary form of ajax response,
      importPDFUsingURL(e.currentTarget.response, template);
    };

    xhr.onerror = function (err) {
      console.log(err)
    }

    xhr.send();
  }

  const importPDFUsingURL = (url, template) => {
    const canvas = pdfCanvasRef.current;
    const context = canvas.getContext("2d");
    context.globalCompositeOperation = "destination-over";

    const requiredHeight = calculateSidebarHeight(template.validations, template.additionalFields.filter(x => x.displayInTemplate === true).length + newlyAddedFields.length);
    const loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then(
      (pdf) => {
        // The document is loaded here...
        pdf.getPage(1).then(function (page) {
          const viewport = page.getViewport({ scale: 2 });
          const canvasHeight = viewport.height >= requiredHeight ? viewport.height : requiredHeight;
          const canvas = pdfCanvasRef.current;
          const drawingCanvas = canvasRef.current;

          drawingCanvas.height = canvasHeight;
          drawingCanvas.width = viewport.width + 300;

          canvas.height = canvasHeight;
          canvas.width = viewport.width;
          leftPane.height = canvasHeight;

          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          initializeQRCodeRect(template);
          initializeCPIRects(cpiLength, template);
          initializeCPNRect(cpnLength, template);     
          initializeAdditionalParamsRects(template.additionalFields, template);
          drawRect();
          page.render(renderContext);
        });
      },
      (reason) => {
        // PDF loading error
        console.error(reason);
      }
    );
  };

  const initializeEditor = (cpiL, cpnL, templateValidations, additionalFields, removed, added, showCPNFlag) => {
    cpiLength = cpiL;
    cpnLength = cpnL;
    validations = templateValidations;
    additionalData = additionalData;
    newlyAddedFields = added;
    removedFields = removed;
    showCPN = showCPNFlag;
  };

  const initializeQRCodeRect = (template = null) => {
    let startX = 30;
    let startY = 37;
    let width = 240;
    let height = 240;

    placeholders.push({
      text: "QR Code",
      textAlign: "center",
      isSelected: false,
      startX,
      startY,
      w: width,
      h: height,
      dragTL: false,
      dragBL: false,
      dragTR: false,
      dragBR: false,
      imgURL: null,
      image: null,
      isSquare: true,
      isPlaceholder: true,
    });

    // if in edit flow
    if (template !== null) {
      const offsetX = 300;

      const ratioX = 0.5;
      const ratioY = 0.5;

      startX = Math.trunc(template.configurations.qrCode.x / ratioX + offsetX);
      startY = Math.trunc(template.configurations.qrCode.y / ratioY);
      width = Math.trunc(template.configurations.qrCode.width / ratioX);
      height = Math.trunc(template.configurations.qrCode.height / ratioY);
    }

    const image = new Image();
    image.onload = (res) => {
      ctx.drawImage(
        image,
        rects[0].startX,
        rects[0].startY,
        rects[0].w,
        rects[0].h
      );
      rects[0].image = image;
    };

    image.onerror = function (err) {
      console.log("err", err);
    };
    image.src = qrCode;

    rects.push({
      text: null,
      textAlign: null,
      isSelected: false,
      type: QR_CODE,
      fontSize: 0,
      startX,
      startY,
      w: width,
      h: height,
      dragTL: false,
      dragBL: false,
      dragTR: false,
      dragBR: false,
      imgURL: qrCode,
      image: image,
      isSquare: true,
      isPlaceholder: false,
    });
  };

  const changeCPNFontSize = (size) => {
    cpnFontSize = size;
    rects.forEach(function (element, index) {
      if (element.type === CPN) {
        this[index].w = Math.trunc(size * cpnLength);
        this[index].h = Math.trunc(size * 2.5);
      }
    }, rects);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };

  const changeCPIFontSize = (size) => {
    cpiFontSize = size;
    CPI_WIDTH = Math.trunc(size * 2.5);
    CPI_HEIGHT = Math.trunc(size * 2.5);

    rects.forEach(function (element, index) {
      if (element.type === CPI) {
        this[index].w = CPI_WIDTH;
        this[index].h = CPI_HEIGHT;
      }
    }, rects);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };

  const changeAdditionalFieldsFontSize = (size) => {
    additionalParamsFontSize = size;
    rects.forEach(function (element, index) {
      if (element.type === ADDITIONAL_PARAM) {
        this[index].w = Math.trunc(size * element.validations.length);
        this[index].h = Math.trunc(size * 2.5);
      }
    }, rects);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };


  const changeCPNFontColor = (color) => {
    cpnFontColor = hexToRgb(color);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };

  const changeCPNTextAlignment = (alignment) => {
    cpnTextAlignment = alignment;
  };

  const changeCPIFontColor = (color) => {
    cpiFontColor = hexToRgb(color);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };

  const changeAdditionalFieldsFontColor = (color) => {
    additionalParamsColor = hexToRgb(color);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };

  const initializeCPIRects = (length, template = null) => {
    cpiLength = length;

    const offsetX = 300;

    const ratioX = 0.5;
    const ratioY = 0.5;

    const GAP_X = 100;
    const GAP_Y = 80;
    const START_X = 30;
    const START_Y = 360;

    const WIDTH = 40;
    const HEIGHT = 40;
    let row = 0;
    const alphabetArr = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    let numbersOnly = false;


    if (validations.cpiFormat.hasNumbers && !validations.cpiFormat.hasAlphabets) {
      numbersOnly = true;
    }

    // if in edit flow
    if (template !== null) {

      const newCPIBoxesLength = cpiLength - template.validations.cpiLength;
      cpiFontSize = Math.trunc(template.configurations.cpiFontSize);
      cpiFontColor = template.configurations.cpiFontColor;
      cpiFontColor.hex = rgbToHex(cpiFontColor.r, cpiFontColor.g, cpiFontColor.b)

      let isOdd = 0;
      for (
        let i = 1;
        i < template.configurations.creditPartyIdentifier.length + 1;
        i++
      ) {
        isOdd = i % 3 === 0 ? 2 : (i % 3) - 1;
        // adds the placeholder)

        placeholders.push({
          text: null,
          textAlign: "center",
          isSelected: false,
          type: PLACEHOLDER,
          startX: START_X + GAP_X * isOdd,
          startY: START_Y + GAP_Y * row,
          w: WIDTH,
          h: HEIGHT,
          dragTL: false,
          dragBL: false,
          dragTR: false,
          dragBR: false,
          image: null,
          imgURL: null,
          isSquare: false,
          isPlaceholder: true, // placeholder cannot be moved or resized
        });
        // record the last X position of the last CPI box
        // to positions the CPN rect
        lastYCPI = START_Y + GAP_Y * row;
        if (isOdd === 2) {
          row++;
        }
      }

      // has increased the cpiLength
      if (newCPIBoxesLength > 0) {
        const currenCount = template.configurations.creditPartyIdentifier.length;
        for (let i = currenCount + 1; i < currenCount + newCPIBoxesLength + 1; i++) {
          isOdd = i % 3 === 0 ? 2 : (i % 3) - 1;
          placeholders.push({
            text: null,
            textAlign: "center",
            isSelected: false,
            type: PLACEHOLDER,
            startX: START_X + GAP_X * isOdd,
            startY: START_Y + GAP_Y * row,
            w: WIDTH,
            h: HEIGHT,
            dragTL: false,
            dragBL: false,
            dragTR: false,
            dragBR: false,
            image: null,
            imgURL: null,
            isSquare: false,
            isPlaceholder: true, // placeholder cannot be moved or resized
          });
          template.configurations.creditPartyIdentifier.push({
            width: template.configurations.creditPartyIdentifier[0].width,
            height: template.configurations.creditPartyIdentifier[0].height,
            x: (((START_X + GAP_X * isOdd) - offsetX) * ratioX),
            y: (START_Y + GAP_Y * row) * ratioY,
          })
          lastYCPI = START_Y + GAP_Y * row;
          if (isOdd === 2) {
            row++;
          }
        }
      } else if (newCPIBoxesLength < 0) {
        // reduced the cpi box length
        for (let i = 0; i < -newCPIBoxesLength; i++) {
          template.configurations.creditPartyIdentifier.pop()
        }
      }
      const actualwidth = Math.trunc(
        template.configurations.creditPartyIdentifier[0].width / ratioX
      );
      const acturalHeight = Math.trunc(
        template.configurations.creditPartyIdentifier[0].height / ratioY
      );

      for (
        let i = 0;
        i < template.configurations.creditPartyIdentifier.length;
        i++
      ) {

        // adds the actual box
        rects.push({
          text: numbersOnly ? i + 1 : alphabetArr[i],
          textAlign: "center",
          type: CPI,
          isSelected: false,
          startX: Math.trunc(
            template.configurations.creditPartyIdentifier[i].x / ratioX +
            offsetX
          ),
          startY: Math.trunc(
            template.configurations.creditPartyIdentifier[i].y / ratioY
          ),
          w: actualwidth,
          h: acturalHeight,
          dragTL: false, // dragTL to check if user is trying to resize the object by dragging the Top Left handle
          dragBL: false, // dragBL to check if user is trying to resize the object by dragging the Bottom Left handle
          dragTR: false,
          dragBR: false,
          image: null,
          imgURL: null,
          isSquare: true,
          isPlaceholder: false,
        });
      }

      return;
    }

    for (let i = 1; i < length + 1; i++) {
      const isOdd = i % 3 === 0 ? 2 : (i % 3) - 1;
      // adds the placeholder)

      placeholders.push({
        text: null,
        textAlign: "center",
        isSelected: false,
        type: PLACEHOLDER,
        startX: START_X + GAP_X * isOdd,
        startY: START_Y + GAP_Y * row,
        w: WIDTH,
        h: HEIGHT,
        dragTL: false,
        dragBL: false,
        dragTR: false,
        dragBR: false,
        image: null,
        imgURL: null,
        isSquare: false,
        isPlaceholder: true, // placeholder cannot be moved or resized
      });

      // adds the actual box
      rects.push({
        text: numbersOnly ? i : alphabetArr[i - 1],
        textAlign: "center",
        type: CPI,
        isSelected: false,
        startX: START_X + GAP_X * isOdd,
        startY: START_Y + GAP_Y * row,
        w: WIDTH,
        h: HEIGHT,
        dragTL: false, // dragTL to check if user is trying to resize the object by dragging the Top Left handle
        dragBL: false, // dragBL to check if user is trying to resize the object by dragging the Bottom Left handle
        dragTR: false,
        dragBR: false,
        image: null,
        imgURL: null,
        isSquare: true,
        isPlaceholder: false,
      });
      // record the last X position of the last CPI box
      // to positions the CPN rect
      lastYCPI = START_Y + GAP_Y * row;
      if (isOdd === 2) {
        row++;
      }
    }
  };

  

  const initializeCPNRect = (length, template = null) => {

    // if Display in Template tick is not checked
    // return without adding objects
    if (!showCPN) {
      return;
    }
    cpnLength = length;

    const alphabet = "abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz";
    const numeric = "123456789123456789123456789123456789";
    let cpnValue = "";

    if (validations.cpnFormat.hasAlphabets && !validations.cpnFormat.hasNumbers) {
      cpnValue = alphabet.substring(0, validations.cpnLength);
    } else if (!validations.cpnFormat.hasAlphabets && validations.cpnFormat.hasNumbers) {
      cpnValue = numeric.substring(0, validations.cpnLength);
    } else {
      cpnValue = alphabet.substring(0, validations.cpnLength);
    }

    // default values
    let startX = 20;
    let startY = lastYCPI + 120;
    let placeholderWidth = 270;
    let width = cpnFontSize * cpnLength;
    let height = 40;

    placeholders.push({
      text: null,
      textAlign: "left",
      type: PLACEHOLDER,
      isSelected: false,
      startX,
      startY,
      w: placeholderWidth,
      h: height,
      dragTL: false,
      dragBL: false,
      dragTR: false,
      dragBR: false,
      image: null,
      isSquare: false,
      imgURL: null,
      isPlaceholder: true,
    });

    // if in edit flow
    if (template !== null) {
      const offsetX = 300;

      const ratioX = 0.5;
      const ratioY = 0.5;

      startX = Math.trunc(
        template.configurations.creditPartyName.x / ratioX + offsetX
      );
      startY = Math.trunc(template.configurations.creditPartyName.y / ratioY);
      width = cpnFontSize * cpnLength;
      height = Math.trunc(
        template.configurations.creditPartyName.height / ratioY
      );
      cpnFontSize = Math.trunc(template.configurations.cpnFontSize);

      cpnFontColor = template.configurations.cpnFontColor;
      cpnTextAlignment = template.configurations.cpnTextAlignment;
      cpnFontColor.hex = rgbToHex(cpnFontColor.r, cpnFontColor.g, cpnFontColor.b)

      // if the user saves the template with Display in template  flag false 
      // and edited it to have the  CPN displayed 
      // position the CPN on top of placeholder in sidebar
      if (template.configurations.creditPartyName.x === 0 && template.configurations.creditPartyName.y === 0) {
        startY = lastYCPI + 120;
        startX = 20;
        width = cpnFontSize * cpnLength;
        height = 40;
      }
    }

    rects.push({
      text: cpnValue,
      textAlign: "left",
      isSelected: false,
      type: CPN,
      startX,
      startY,
      w: width,
      h: height,
      dragTL: false,
      dragBL: false,
      dragTR: false,
      dragBR: false,
      image: null,
      isSquare: false,
      imgURL: null,
      isPlaceholder: false,
    });
  };

  const initializeSingleDigitRects = (length, start_Y, start_X, storeIdParam, template = null) => {
    let storeIdLength = length;

    let offsetX = 30;

    const ratioX = 0.5;
    const ratioY = 0.5;

    const GAP_X = 50;
    const GAP_Y = 60;
    let START_X = start_X;
    let START_Y = start_Y;

    const WIDTH = 40;
    const HEIGHT = 40;
   
    const alphabetArr = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

    let numbersOnly = true;

    let mpaKey = storeIdParam.mpaKey;
    let mpaFieldName = storeIdParam.mpaFieldName; //'Store Id';
    let minLength = storeIdParam.validation.minLength;
    let format = storeIdParam.validation.format;
    let requiresUserInput = storeIdParam.requiresUserInput;
    let displayInTemplate = storeIdParam.displayInTemplate;

    // if in edit flow
    if (template !== null) {
      let isOdd = 0;
      offsetX = 300;
      for (
        let i = 1;
        i < storeIdLength;
        i++
      ) {
        isOdd = i % 5 === 0 ? 4 : (i % 5) - 1;
        // adds the placeholder)
        placeholders.push({
          text: null,
          title: storeIdParam.mpaFieldName,
          textAlign: "center",
          isSelected: false,
          type: PLACEHOLDER,
          startX: START_X + GAP_X * isOdd,
          startY: START_Y + GAP_Y * row,
          w: WIDTH,
          h: HEIGHT,
          dragTL: false,
          dragBL: false,
          dragTR: false,
          dragBR: false,
          image: null,
          imgURL: null,
          isSquare: false,
          isPlaceholder: true, // placeholder cannot be moved or resized
          mpaKey,
          mpaFieldName,
          requiresUserInput,
          displayInTemplate,
          validations: {
            length: storeIdLength,
            minLength,
            format: {
              hasAlphabets: storeIdParam.validation.hasAlphabets,
              hasNumbers: storeIdParam.validation.hasNumbers,
              hasSpecialChars: storeIdParam.validation.hasSpecialChars,
            }
          }
        });

        // record the last X position of the last StoreId box      
        lastYStoreId = START_Y + GAP_Y * row;  
        if (isOdd === 4) {
          row++;
        }
      }
      const actualwidth = Math.trunc(
        template.configurations.storeID[0].width / ratioX
      );
      const acturalHeight = Math.trunc(
        template.configurations.storeID[0].height / ratioY
      );

      for (
        let i = 0;
        i < template.configurations.storeID.length;
        i++
      ) {
        // adds the actual box
        rects.push({
          text: numbersOnly ? i + 1 : alphabetArr[i],
          textAlign: "center",
          type: SINGLE_DIGIT_TYPE,
          isSelected: false,
          startX: Math.trunc(
            template.configurations.storeID[i].x / ratioX +
            offsetX
          ),
          startY: Math.trunc(
            template.configurations.storeID[i].y / ratioY
          ),
          w: actualwidth,
          h: acturalHeight,
          dragTL: false, // dragTL to check if user is trying to resize the object by dragging the Top Left handle
          dragBL: false, // dragBL to check if user is trying to resize the object by dragging the Bottom Left handle
          dragTR: false,
          dragBR: false,
          image: null,
          imgURL: null,
          isSquare: true,
          isPlaceholder: false,
          mpaKey,
          mpaFieldName,
          requiresUserInput,
          displayInTemplate,
          validations: {
            length: storeIdLength,
            minLength,
            format: {
              hasAlphabets: storeIdParam.validation.hasAlphabets,
              hasNumbers: storeIdParam.validation.hasNumbers,
              hasSpecialChars: storeIdParam.validation.hasSpecialChars,
            }
          }
        });
      }

      return;
    }

    for (let i = 1; i < length + 1; i++) {
      const isOdd = i % 5 === 0 ? 4 : (i % 5) - 1;
      // adds the placeholder)

      placeholders.push({
        text: null,
        title: storeIdParam.mpaFieldName,
        textAlign: "center",
        isSelected: false,
        type: PLACEHOLDER,
        startX: START_X + GAP_X * isOdd,
        startY: START_Y + GAP_Y * row,
        w: WIDTH,
        h: HEIGHT,
        dragTL: false,
        dragBL: false,
        dragTR: false,
        dragBR: false,
        image: null,
        imgURL: null,
        isSquare: false,
        isPlaceholder: true, // placeholder cannot be moved or resized
      });

      // adds the actual box
      rects.push({
        text: numbersOnly ? i : alphabetArr[i - 1],
        textAlign: "center",
        type: SINGLE_DIGIT_TYPE,
        isSelected: false,
        startX: START_X + GAP_X * isOdd,
        startY: START_Y + GAP_Y * row,
        w: WIDTH,
        h: HEIGHT,
        dragTL: false, // dragTL to check if user is trying to resize the object by dragging the Top Left handle
        dragBL: false, // dragBL to check if user is trying to resize the object by dragging the Bottom Left handle
        dragTR: false,
        dragBR: false,
        image: null,
        imgURL: null,
        isSquare: true,
        isPlaceholder: false,
        mpaKey,
        mpaFieldName,
        requiresUserInput,
        displayInTemplate,
        validations: {
          length: length,
          minLength,
          format: {
            hasAlphabets: storeIdParam.validation.hasAlphabets,
            hasNumbers: storeIdParam.validation.hasNumbers,
            hasSpecialChars: storeIdParam.validation.hasSpecialChars,
          }
        }
      });
      // record the last X position of the last Store ID box
      // to positions the Store ID rect 
      lastYStoreId = START_Y + GAP_Y * row;   
      if (isOdd === 4) {
        row++;
      }
      
    }
  };

  const getValue = (hasNumbers, hasAlphabets, paramLength) => {

    const alphabet = "abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz";
    const numeric = "123456789123456789123456789123456789123456789";
    let parameterValue = "";

    if (hasAlphabets && !hasNumbers) {
      parameterValue = alphabet.substring(0, paramLength);
    } else if (!hasAlphabets && hasNumbers) {
      parameterValue = numeric.substring(0, paramLength);
    } else {
      parameterValue = alphabet.substring(0, paramLength);
    }
    return parameterValue;
  }


  const initializeAdditionalParamsRects = (additionalParams = [], template = null) => {
    
    // default values
    let startX = 20;
    let startY = lastYCPI 
    let width = null;
    let placeholderWidth = 270;

    let height = 40;
    let fontSize = 16;
    let fontColor = rgbToHex(0, 0, 0);

    let lastIndex = 0;
    let offsetFromCPN = 160;

    // sidepanel widht
    const offsetX = 300;

    // pdf scale ration
    const ratioX = 0.5;
    const ratioY = 0.5;

    // if the CPN Display in Template flag is false
    // reduce the offsetFrom the previous objects
    if (!showCPN) {
      offsetFromCPN = 40;
    }

    for (let i = 0; i < additionalParams.length; i++) {
      let parameter = additionalParams[i];
      let paramLength = parameter.validation.length;

      if (!parameter.displayInTemplate) {
        // fields that needs not to be displaeyd on template but 
        // that requires a user input
        requiresUserInputOnlyFields.push(parameter);
        continue;
      }

      let parameterValue = getValue(parameter.validation.format.hasNumbers, parameter.validation.format.hasAlphabets, paramLength);

      // default values
      startX = 20;
      startY = lastYCPI + offsetFromCPN + (110 * (lastIndex + 1));

      let placeholderStartX = 20;
      let placeholderStartY = lastYCPI + offsetFromCPN + (110 * (lastIndex + 1));

      width = 16 * paramLength;

      fontSize = 16;
      fontColor = rgbToHex(0, 0, 0);

      let mpaKey = parameter.mpaKey;
      let mpaFieldName = parameter.mpaFieldName;
      let minLength = parameter.validation.minLength;
      let format = parameter.validation.format;
      let requiresUserInput = parameter.requiresUserInput;
      let displayInTemplate = parameter.displayInTemplate;

      // if in edit flow
      if (template !== null) {

        startX = Math.trunc(
          parameter.configuration.position.x / ratioX + offsetX
        );
        startY = Math.trunc(parameter.configuration.position.y / ratioY);
        width = parameter.validation.length * parameter.configuration.fontSize;
        height = Math.trunc(
          parameter.configuration.position.height / ratioY
        );
        fontSize = Math.trunc(parameter.configuration.fontSize);
        const color = parameter.configuration.fontColor;
        fontColor = rgbToHex(color.r, color.g, color.b);

        const index = removedFields.findIndex(x => x.mpaKey === mpaKey);
        // if the field is removed return
        if (index > -1) {
          if (parameter.requiresUserInput) {
            // fields that needs not to be displaeyd on template but 
            // that requires a user input
            requiresUserInputOnlyFields.push(parameter);
          }
          continue;
        }
      }

      // if(parameter.mpaKey == "storeID"){
      //   showStoreId = true;
      //   initializeStoreIDRects(STORE_LENGTH, placeholderStartY, placeholderStartX, parameter, template)
      // } 
      if(!parameter.isString){
        showStoreId = true;
        initializeSingleDigitRects(parameter.lengthMax, placeholderStartY, placeholderStartX, parameter, template)
      } 
      else{

        if ( showStoreId ){
          startY = lastYStoreId  + (50 * (lastIndex + 1));
          placeholderStartY = lastYStoreId + (50 * (lastIndex + 1));
        }

        placeholders.push({
          text: null,
          textAlign: "left",
          title: mpaFieldName,
          type: PLACEHOLDER,
          isSelected: false,
          startX: placeholderStartX,
          startY: placeholderStartY,
          w: placeholderWidth,
          h: height,
          dragTL: false,
          dragBL: false,
          dragTR: false,
          dragBR: false,
          image: null,
          isSquare: false,
          imgURL: null,
          isPlaceholder: true,
        });
        
        rects.push({
          text: parameterValue,
          title: mpaFieldName,
          textAlign: "left",
          isSelected: false,
          type: ADDITIONAL_PARAM,
          startX,
          startY,
          w: width,
          h: height,
          dragTL: false,
          dragBL: false,
          dragTR: false,
          dragBR: false,
          image: null,
          fontColor,
          fontSize,
          isSquare: false,
          imgURL: null,
          isPlaceholder: false,
          mpaKey,
          mpaFieldName,
          requiresUserInput,
          displayInTemplate,
          validations: {
            length: paramLength,
            minLength,
            format
          }
        });
      }      
      lastIndex++;

    }
    

    for (let i = 0; i < newlyAddedFields.length; i++) {
      let parameter = newlyAddedFields[i];
      let parameterValue = getValue(parameter.hasNumbers, parameter.hasAlphabets, parameter.lengthMax);
      startX = 20;
      // startY = (lastYStoreId - 60) + (80 * (lastIndex + 1));
      startY = lastYCPI + offsetFromCPN + (110 * (lastIndex + 1));
      width = 16 * parameter.lengthMax;
      
      let placeholderStartX = 20;
      let placeholderStartY = lastYCPI + offsetFromCPN + (110 * (lastIndex + 1));

      if(!parameter.isString){
        showStoreId = true;
        initializeSingleDigitRects(parameter.lengthMax, placeholderStartY, placeholderStartX, parameter)
      } 
      else{
        placeholders.push({
          text: null,
          textAlign: "left",
          title: parameter.fieldName,
          type: PLACEHOLDER,
          isSelected: false,
          startX,
          startY,
          w: placeholderWidth,
          h: height,
          dragTL: false,
          dragBL: false,
          dragTR: false,
          dragBR: false,
          image: null,
          isSquare: false,
          imgURL: null,
          isPlaceholder: true,
        });
  
        rects.push({
          text: parameterValue,
          textAlign: "left",
          title: parameter.fieldName,
          isSelected: false,
          type: ADDITIONAL_PARAM,
          startX,
          startY,
          w: width,
          h: height,
          dragTL: false,
          dragBL: false,
          dragTR: false,
          dragBR: false,
          image: null,
          fontColor,
          fontSize,
          isSquare: false,
          imgURL: null,
          isPlaceholder: false,
          mpaKey: parameter.mpaKey,
          mpaFieldName: parameter.fieldName,
          requiresUserInput: parameter.requiresUserInput,
          displayInTemplate: parameter.displayInTemplate,
          validations: {
            length: parameter.validation.lengthMax,
            minLength: parameter.validation.lengthMin,
            format: {
              hasAlphabets: parameter.validation.format.hasAlphabets,
              hasNumbers: parameter.validation.format.hasNumbers,
              hasSpecialChars: parameter.validation.format.hasSpecialChars,
            }
          }
        });
      }
      
      lastIndex++;
    }
  };


  // draws the section titles with a seperator on the left side of the canvas
  const drawLeftSidebarSection = () => {
    if (rects.length > 0) {
      // draw the static content

      // set line stroke and line width
      ctx.strokeStyle = "lightgrey";
      ctx.lineWidth = 1;
      ctx.setLineDash([]);

      // draw a horizontal line after QR code
      ctx.beginPath();
      ctx.moveTo(0, 300);
      ctx.lineTo(300, 300);
      ctx.stroke();

      ctx.fillStyle = "grey";
      ctx.font = `bold 14px ${fontName}`;

      // draw the title of the section
      ctx.fillText("Credit Party Identifier", 20, 320);

      // draw a line to seperate cpi from cpn
      ctx.beginPath();
      ctx.moveTo(0, lastYCPI + 70);
      ctx.lineTo(300, lastYCPI + 70);
      ctx.stroke();

      // ignore the CPN header if the Display in Template flag is false
      if (showCPN) {
        // draw the title of the section
        ctx.fillText("Credit Party Name", 20, lastYCPI + 90);

        // draw a line to seperate cpn from additiona data fields
        ctx.beginPath();
        ctx.moveTo(0, lastYCPI + 180);
        ctx.lineTo(300, lastYCPI + 180);
        ctx.stroke();
        ctx.fillStyle = "black";
        ctx.fillText("Additional Fields", 20, lastYCPI + 210);

      } else {
        ctx.fillStyle = "black";
        ctx.fillText("Additional Fields", 20, lastYCPI + 90);
      }

    }
  }

  // main function that draws the actula rectangles
  const drawRect = () => {
    canvasObjects = [];
    drawLeftSidebarSection();
    let currentTitle = "";
    // let letterCount = 0;
    // draw the placeholders 
    for (var p = 0; p < placeholders.length; p++) {
      ctx.fillStyle = "#222222";
      const placeholder = placeholders[p];

      // draw the title in additional fields
      if (placeholder.title && placeholder.title !== null) {
        // if ( !drawStoreId && placeholder.title == STORE_ID_TITLE){
        //   drawStoreId = true;
        //   ctx.fillStyle = "grey";
        //   ctx.font = `bold 14px ${fontName}`;
        //   ctx.textAlign = "left"
        //   ctx.fillText(
        //     placeholder.title,
        //     20, // x cordinate
        //     placeholder.startY - 20
        //   );
        // }
        // else if (placeholder.title != STORE_ID_TITLE){
        //   ctx.fillStyle = "grey";
        //   ctx.font = `bold 14px ${fontName}`;
        //   ctx.textAlign = "left"
        //   ctx.fillText(
        //     placeholder.title,
        //     20, // x cordinate
        //     placeholder.startY - 20
        //   );
        // }
        // letterCount++;
        if(placeholder.title != currentTitle) {
          currentTitle = placeholder.title;
          ctx.fillStyle = "grey";
          ctx.font = `bold 14px ${fontName}`;
          ctx.textAlign = "left"
          ctx.fillText(
            placeholder.title,
            20, // x cordinate
            placeholder.startY -20 //- (letterCount/5 < 2 ? (letterCount/5 + 1)*20 : (letterCount/5 +1)*30)
          );
          // letterCount = 0;
        }
      }

      // draw the dashed rectangle for the placeholder
      ctx.setLineDash([6]);
      ctx.strokeRect(
        placeholder.startX,
        placeholder.startY,
        placeholder.w,
        placeholder.h
      );
      ctx.fillStyle = "grey";

      // draw the placeholder inner text
      if (placeholder.text != null) {
        setFontAndColor(ctx, placeholder);
        let offsetX = 0;
        if (placeholder.textAlign === "center") {
          offsetX = placeholder.w / 2;
        }
        ctx.textAlign = placeholder.textAlign;

        ctx.fillText(
          placeholder.text,
          placeholder.startX + offsetX,
          placeholder.startY + placeholder.h / 2 + 3
        );
      }
    }

    // draw the draggable objects
    for (var i = 0; i < rects.length; i++) {
      ctx.fillStyle = "#222222";
      ctx.strokeStyle = "grey";
      var rect = rects[i];

      ctx.strokeRect(rect.startX, rect.startY, rect.w, rect.h);
      if (rect.image !== null) {
        ctx.drawImage(rect.image, rect.startX, rect.startY, rect.w, rect.h);
      }

      // draw handles if the object is selected
      if (rect.isSelected) {
        ctx.fillStyle = rectHandleColor;
        drawHandles(rect);
        drawObjectName(rect);
      }


      // draw text only if the object has text
      if (rect.text != null) {
        setFontAndColor(ctx, rect);
        ctx.textAlign = rect.textAlign;
        ctx.textBaseline = "middle";
        if (rect.textAlign === "center") {
          ctx.fillText(
            rect.text,
            rect.startX + rect.w / 2,
            rect.startY + rect.h / 2
          );
        } else if (rect.textAlign === "left") {
          ctx.fillText(rect.text, rect.startX, rect.startY + rect.h / 2);
        }
      }

      for (var hl = 0; hl < helpLines.length; hl++) {
        const helpLine = helpLines[hl];
        drawDashedLine(
          helpLine.startX,
          helpLine.startY,
          helpLine.endX,
          helpLine.endY
        );
      }
    }
  };

  // draw items on preview canvas
  const drawOnPreviewCanvas = (offsetX, context, cpi, cpn) => {
    try {
      for (var i = 0; i < rects.length; i++) {
        context.fillStyle = "#222222";
        context.strokeStyle = "grey";
        var rect = rects[i];
        if (rect.image !== null) {
          context.drawImage(rect.image, rect.startX - offsetX, rect.startY, rect.w, rect.h);
        }
        context.fillStyle = rectHandleColor;

        if (rect.text != null) {
          setFontAndColor(context, rect);
          context.textAlign = rect.textAlign;
          context.textBaseline = "middle";
          if (rect.textAlign === "center") {

            if (rect.type === CPN || rect.type === CPI) {
              context.fillText(
                rect.type === CPI ? cpi.charAt(i - 1) : cpn,
                rect.startX - offsetX + rect.w / 2,
                rect.startY + rect.h / 2
              );
            }
            else if(rect.type === SINGLE_DIGIT_TYPE){
              context.fillText(rect.text.charAt(i - 1), rect.startX - offsetX, rect.startY + rect.h / 2);
            } else if(rect.type === ADDITIONAL_PARAM) {
              context.fillText(rect.text, rect.startX - offsetX, rect.startY + rect.h / 2);
            }
          } else if (rect.textAlign === "left") {
            if (rect.type === CPN) {
              context.fillText(cpn ? cpn : rect.text, rect.startX - offsetX, rect.startY + rect.h / 2);
            }
            else if(rect.type === SINGLE_DIGIT_TYPE){
              context.fillText(rect.text.charAt(i - 1), rect.startX - offsetX, rect.startY + rect.h / 2);
            }
             else if(rect.type === ADDITIONAL_PARAM) {
              context.fillText(rect.text, rect.startX - offsetX, rect.startY + rect.h / 2);
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  };

  const setFontAndColor = (context, rect) => {
    context.font = `bold 14px ${fontName}`;
    if (rect.type === CPI) {
      context.fillStyle = cpiFontColor.hex;
      context.font = `bold ${cpiFontSize * 2}px ${fontName}`;
    } else if (rect.type === CPN) {
      context.fillStyle = cpnFontColor.hex;
      context.font = `bold ${cpnFontSize * 2}px ${fontName}`;
    } else if (rect.type === ADDITIONAL_PARAM || rect.type === SINGLE_DIGIT_TYPE) {
      context.fillStyle = additionalParamsColor.hex;
      context.font = `bold ${additionalParamsFontSize * 2}px ${fontName}`;
    }
  }


  const restCanvas = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    cpiFontColor = {
      r: 0,
      g: 0,
      b: 0,
      hex: "#111",
    };
    cpnFontColor = {
      r: 0,
      g: 0,
      b: 0,
      hex: "#111",
    };
    additionalParamsColor = {
      r: 0,
      g: 0,
      b: 0,
      hex: "#111",
    };
    rects = [];
    placeholders = [];
    canvasObjects = [];
    helpLines = [];
    cpiFontSize = 16;
    cpnFontSize = 16;
    cpnTextAlignment = 'Left';
    additionalParamsFontSize = 16;
    requiresUserInputOnlyFields = [];

    drawInstructions();
  };

  const calculateSidebarHeight = (validations, additionalDataLength ) => {
     // space taken for QR Code 360
    // space taken for CPI squares row 120
    return 360 + (Math.ceil(validations.cpiLength / 3) * 120) + (additionalDataLength * 130) //+ 120 + 40 +180 ;
  }

  const importPDF = (file, validationsPassed, additionalData, path) => {
    filePath = path;
    const canvas = pdfCanvasRef.current;
    const context = canvas.getContext("2d");
    context.globalCompositeOperation = "destination-over";

    // filter the fields that are being displayed
    // below array contains both userInput Only and userInput + displayInTemplate
    const additionalDataLength = additionalData.filter(x => x.displayInTemplate === true).length;
    const requiredHeight = calculateSidebarHeight(validationsPassed, additionalDataLength);

    var fileReader = new FileReader();

    //Step 2: Read the file using file reader
    fileReader.onload = function () {
      //Step 4:turn array buffer into typed array
      var typedarray = new Uint8Array(this.result);

      //Step 5:pdfjs should be able to read this
      const loadingTask = pdfjsLib.getDocument(typedarray);
      loadingTask.promise.then((pdf) => {
        // The document is loaded here...
        pdf.getPage(1).then(function (page) {
          const viewport = page.getViewport({ scale: 2 });
          const canvas = pdfCanvasRef.current;
          const drawingCanvas = canvasRef.current;
          const canvasHeight = viewport.height >= requiredHeight ? viewport.height : requiredHeight;
          drawingCanvas.height = canvasHeight;
          drawingCanvas.width = viewport.width + 300;

          canvas.height = canvasHeight;
          canvas.width = viewport.width;

          leftPane.height = canvasHeight;

          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport: viewport,
          };
          page.render(renderContext);

          var img = new Image();

          img.onload = () => {
            ctx.drawImage(img, 100, 100, img.width, img.height);
          };
          img.src = canvas.toDataURL();

          cpiFontColor = {
            r: 0,
            g: 0,
            b: 0,
            hex: "#111",
          };
          cpnFontColor = {
            r: 0,
            g: 0,
            b: 0,
            hex: "#111",
          };
          additionalParamsColor = {
            r: 0,
            g: 0,
            b: 0,
            hex: "#111",
          };
          rects = [];
          helpLines = [];
          placeholders = [];
          canvasObjects = [];
          cpiFontSize = 16;
          cpnFontSize = 16;
          cpnTextAlignment = 'Left';
          additionalParamsFontSize = 16;
          requiresUserInputOnlyFields = [];

          initializeQRCodeRect();
          initializeCPIRects(cpiLength);
          initializeCPNRect(cpnLength);
          initializeAdditionalParamsRects(additionalData)
          drawRect();
        });
      });
    };
    //Step 3:Read the file as ArrayBuffer
    fileReader.readAsArrayBuffer(file);
  };

  const onClick = (e) => {
    const x = parseInt(e.clientX - canvas.getBoundingClientRect().left);
    const y = parseInt(e.clientY - canvas.getBoundingClientRect().top);

    rects.forEach(function (element, index) {
      if (
        !element.isPlaceholder &&
        y > element.startY &&
        y < element.startY + element.h &&
        x > element.startX &&
        x < element.startX + element.w
      ) {
        this[index].isSelected = true;
      } else {
        this[index].isSelected = false;
      }
    }, rects);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawRect();
  };

  const startDrawing = (e) => {
    e.persist();
    e.stopPropagation();
    x = parseInt(e.clientX - canvas.getBoundingClientRect().left);
    y = parseInt(e.clientY - canvas.getBoundingClientRect().top);

    mouseX = x;
    mouseY = y;

    dragok = false;
    for (var i = 0; i < rects.length; i++) {
      var r = rects[i];
      if (
        mouseX > r.startX &&
        mouseX < r.startX + r.w &&
        mouseY > r.startY &&
        mouseY < r.startY + r.h &&
        !r.isPlaceholder
      ) {
        // if yes, set that rects isDragging=true
        if (mouseX < canvas.width + r.w - 10 && mouseX > canvas.offsetLeft && mouseY < canvas.height + r.h - 20 && mouseY > canvas.offsetTop) {
          dragok = true;
          r.isDragging = true;
        }
      }
    }

    for (var k = 0; k < rects.length; k++) {
      const rect = rects[k];

      if (rect.type === CPI || rect.type === CPN || rect.type === ADDITIONAL_PARAM || rect.type === SINGLE_DIGIT_TYPE) {
        continue;
      }
      // 4 cases:
      // 1. top left
      if (
        checkCloseEnough(mouseX, rect.startX) &&
        checkCloseEnough(mouseY, rect.startY)
      ) {
        rect.dragTL = true;
      }
      // 2. top right
      else if (
        checkCloseEnough(mouseX, rect.startX + rect.w) &&
        checkCloseEnough(mouseY, rect.startY)
      ) {
        rect.dragTR = true;
      }
      // 3. bottom left
      else if (
        checkCloseEnough(mouseX, rect.startX) &&
        checkCloseEnough(mouseY, rect.startY + rect.h)
      ) {
        rect.dragBL = true;
      }
      // 4. bottom right
      else if (
        checkCloseEnough(mouseX, rect.startX + rect.w) &&
        checkCloseEnough(mouseY, rect.startY + rect.h)
      ) {
        rect.dragBR = true;
      }
      // (5.) none of them
      else {
        // handle not resizing
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawRect();
    }
  };

  const checkCloseEnough = (p1, p2) => {
    return Math.abs(p1 - p2) < closeEnough;
  };

  const finishDrawing = () => {
    for (var i = 0; i < rects.length; i++) {
      rects[i].isDragging = false;
      rects[i].dragTL =
        rects[i].dragTR =
        rects[i].dragBL =
        rects[i].dragBR =
        false;
    }
  };

  // mouse move event
  const draw = (e) => {
    e.persist();

    x = parseInt(e.clientX - canvas.getBoundingClientRect().left);
    y = parseInt(e.clientY - canvas.getBoundingClientRect().top);

    let needsRerendering = false;

    mouseX = x;
    mouseY = y;
    // if we're dragging anything...
    if (dragok) {
      // tell the browser we're handling this mouse event
      e.preventDefault();
      e.stopPropagation();

      // get the current mouse position

      // calculate the distance the mouse has moved
      // since the last mousemove
      var dx = mouseX - startX;
      var dy = mouseY - startY;

      // move each rect that isDragging
      // by the distance the mouse has moved
      // since the last mousemove
      for (var i = 0; i < rects.length; i++) {
        var r = rects[i];
        if (r.isDragging) {
          r.startX += dx;
          r.startY += dy;
          needsRerendering = true;
        }

        if (r.isDragging) {
          helpLines = [];
          for (var t = 0; t < rects.length; t++) {
            const checkRect = rects[t];

            // if the rectangles are out of the pdf editor return doing nothing
            if (r === checkRect || checkRect.startX < 300 || r.startX < 300) {
              continue;
            }

            //------------------
            //  15px gap 
            //------------------
            //  15px gap
            //------------------
            const checkRectBottomY = checkRect.startY + checkRect.h;
            const checkRectTopRightX = checkRect.startX + checkRect.w;
            const movingObjBottomY = r.startY + r.h;
            const movingObjTopRightX = r.startX + r.w;
            const isLeft = checkRect.startX > r.startX;


            // bottom horizontal line of the moving object to the top corner of the static object
            if (checkRectBottomY === r.startY) {
              checkAndEditDuplicates({
                startX: isLeft ? r.startX - 100 : r.startX + r.w + 100,
                startY: r.startY,
                endX: checkRect.startX,
                endY: checkRectBottomY,
              });
            }

            if (checkRectBottomY === movingObjBottomY) {
              checkAndEditDuplicates({
                startX: r.startX,
                startY: movingObjBottomY,
                endX: checkRect.startX,
                endY: checkRectBottomY,
              });
            }

            if (checkRect.startY === r.startY) {
              checkAndEditDuplicates({
                startX: r.startX,
                startY: r.startY,
                endX: checkRect.startX,
                endY: checkRect.startY,
              });
            }

            if (checkRect.startY === movingObjBottomY) {
              checkAndEditDuplicates({
                startX: r.startX,
                startY: movingObjBottomY,
                endX: checkRect.startX,
                endY: checkRect.startY,
              });
            }


            // checking vertical
            if (checkRect.startX === r.startX) {
              checkAndEditDuplicates({
                startX: checkRect.startX,
                startY: r.startY,
                endX: checkRect.startX,
                endY: checkRect.startY,
              });
            }

            if (checkRect.startX === movingObjTopRightX) {
              checkAndEditDuplicates({
                startX: checkRect.startX,
                startY: r.startY,
                endX: checkRect.startX,
                endY: checkRect.startY,
              });
            }

            if (checkRectTopRightX === r.startX) {
              checkAndEditDuplicates({
                startX: checkRectTopRightX,
                startY: r.startY,
                endX: checkRectTopRightX,
                endY: checkRect.startY,
              });
            }


            if (checkRectTopRightX === movingObjTopRightX) {
              checkAndEditDuplicates({
                startX: checkRectTopRightX,
                startY: r.startY,
                endX: checkRectTopRightX,
                endY: checkRect.startY,
              });
            }

          }
        }
      }
    }

    // reset the starting mouse position for the next mousemove
    startX = mouseX;
    startY = mouseY;

    for (var j = 0; j < rects.length; j++) {
      const rect = rects[j];
      if (rect.isPlaceholder) {
        continue;
      }
      if (rect.isSquare) {
        if (rect.dragTL) {
          const previousBRY = rect.startY + rect.h;
          rect.w += rect.startX - mouseX;
          rect.h += rect.startX - mouseX;
          rect.startX = mouseX;
          rect.startY = previousBRY - rect.h;
          needsRerendering = true;
        } else if (rect.dragTR) {
          rect.w += rect.startY - mouseY;
          rect.h += rect.startY - mouseY;
          rect.startY = mouseY;
          needsRerendering = true;
        } else if (rect.dragBL) {
          rect.w += rect.startX - mouseX;
          rect.h += rect.startX - mouseX;
          rect.startX = mouseX;
          needsRerendering = true;
        } else if (rect.dragBR) {
          rect.w = Math.abs(rect.startX - mouseX);
          rect.h = Math.abs(rect.startX - mouseX);
          needsRerendering = true;
        }
      } else {
        if (rect.dragTL) {
          rect.w += rect.startX - mouseX;
          rect.h += rect.startY - mouseY;
          rect.startX = mouseX;
          rect.startY = mouseY;
          needsRerendering = true;
        } else if (rect.dragTR) {
          rect.w = Math.abs(rect.startX - mouseX);
          rect.h += rect.startY - mouseY;
          rect.startY = mouseY;
          needsRerendering = true;
        } else if (rect.dragBL) {
          rect.w += rect.startX - mouseX;
          rect.h = Math.abs(rect.startY - mouseY);
          rect.startX = mouseX;
          needsRerendering = true;
        } else if (rect.dragBR) {
          rect.w = Math.abs(rect.startX - mouseX);
          rect.h = Math.abs(rect.startY - mouseY);
          needsRerendering = true;
        }
      }
    }

    if (needsRerendering) {
      // clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // redraw the objects
      drawRect();
    }

    // change cursor
    let showDefaultPointer = true;
    for (var m = 0; m < canvasObjects.length; m++) {
      if (ctx.isPointInPath(canvasObjects[m], mouseX, mouseY)) {
        let cursor = "move";

        if (canvasObjects[m].type === CPN || canvasObjects[m].type === CPI || canvasObjects[m].type === ADDITIONAL_PARAM || canvasObjects[m].type === SINGLE_DIGIT_TYPE) {
          cursor = "not-allowed";
        } else {
          switch (canvasObjects[m].corner) {
            case 1:
              cursor = "nw-resize";
              break;
            case 2:
              cursor = "ne-resize";
              break;
            case 3:
              cursor = "se-resize";
              break;
            case 4:
              cursor = "sw-resize";
              break;
            case 5:
              cursor = "move";
              break;

            default:
              cursor = "wait";
          }
        }
        document.body.style.cursor = cursor;
        showDefaultPointer = false;
      }
    }

    if (showDefaultPointer) {
      document.body.style.cursor = "auto";
    }
  };

  const checkAndEditDuplicates = (line) => {
    let found = false;
    // find and remove duplicates
    helpLines.forEach(function (element, index) {
      // matches horizontal line
      if (
        element.startY === line.startY &&
        line.startY === line.endY &&
        element.startY === element.endY
      ) {
        if (element.startX > line.startX) {
          element.startX = line.startX;
        }

        if (element.endX < line.endX) {
          element.endx = line.endX;
        }
        found = true;
        // matches vertical line
      } else if (
        element.startX === line.startX &&
        line.startX === line.endX &&
        element.startX === element.endX
      ) {
        if (element.startY > line.startY) {
          element.startY = line.startY;
        }

        if (element.endY < line.endY) {
          element.endY = line.endY;
        }
        found = true;
      }
    }, helpLines);

    if (!found) {
      helpLines.push(line);
    }
  };

  // corner values
  // 1 => TL
  // 2 => BL
  // 3 => BR
  // 4 => TR
  const drawCircle = (x, y, radius, corner, type) => {
    const circle = new Path2D();

    circle.corner = corner;
    circle.type = type;
    ctx.fillStyle = rectHandleColor;
    circle.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fill(circle);
    ctx.fillStyle = "#fff";
    canvasObjects.push(circle);
  };

  const drawDashedLine = (startX, startY, endX, endY) => {
    ctx.fillStyle = "#FFA500";
    ctx.strokeStyle = "#faed27";
    // ctx.setLineDash([5, 3]); /*dashes are 5px and spaces are 3px*/
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
    ctx.strokeStyle = "grey";
  };

  const drawHandles = (rect) => {
    drawCircle(rect.startX, rect.startY, closeEnough, 1, rect.type);
    drawCircle(rect.startX + rect.w, rect.startY, closeEnough, 2, rect.type);
    drawCircle(
      rect.startX + rect.w,
      rect.startY + rect.h,
      closeEnough,
      3,
      rect.type
    );
    drawCircle(rect.startX, rect.startY + rect.h, closeEnough, 4, rect.type);
  };

  const drawObjectName = (rect) => {
    if (rect.startX > 300 && !rect.isDragging) {
      let text = "";
      if (rect.type === CPN) {
        text = "Credit Party Name";
      } else if (rect.type === ADDITIONAL_PARAM) {
        text = rect.title;
      } else if (rect.type === CPI) {
        text = "Credit Party Identifier";
      }else if (rect.type === SINGLE_DIGIT_TYPE) {
        text = rect.mpaFieldName
      }
    
      ctx.font = `bold 12px ${fontName}`;
      ctx.textAlign = "center";
      ctx.fillStyle = "#faed27";

      ctx.fillText(
        text,
        rect.startX + rect.w / 2,
        rect.startY - 20
      );
    }

  };

  const previewTemplate = (cpi, cpn) => {
    //grab the context from your destination canvas
    previewCanvas = previewCanvasRef.current;
    previewCanvas.height = pdfCanvas.height;
    previewCanvas.width = pdfCanvas.width;
    var destCtx = previewCanvas.getContext('2d');
    //call its drawImage() function passing it the source canvas directly
    destCtx.drawImage(pdfCanvas, 0, 0);
    setTimeout(function () {
      drawOnPreviewCanvas(300, destCtx, cpi, cpn);
    }, 2000);

  };

  const getConfigs = () => {
    const qrCode = rects.filter((x) => x.type === QR_CODE);

    const offsetX = 300;

    const ratioX = 0.5;
    const ratioY = 0.5;

    const actualX = ratioX * (qrCode[0].startX - offsetX);
    const actualY = ratioY * qrCode[0].startY;

    const actualW = ratioX * qrCode[0].w;
    const actualH = ratioY * qrCode[0].h;

    // get the CPI square
    const cpiSquares = rects.filter((x) => x.type === CPI);

    // const storeIdSquares = rects.filter((x) => x.type === SINGLE_DIGIT_TYPE);

    let cpnSquare = rects.filter((x) => x.type === CPN);
    let cpnPosition = {
      y: 0,
      x: 0,
      width: 0,
      height: 0,
    };

    // if the Display in template flag is false for 
    // Credit party identifier set default value
    if (showCPN) {
      cpnPosition = {
        x: Math.trunc(ratioX * (cpnSquare[0].startX - offsetX)),
        y: Math.trunc(ratioY * cpnSquare[0].startY),
        width: Math.trunc(ratioX * cpnSquare[0].w),
        height: Math.trunc(ratioY * cpnSquare[0].h),
      }
    }

    const creditPartyIdentifier = cpiSquares.map((square) => ({
      x: Math.trunc(ratioX * (square.startX - offsetX)),
      y: Math.trunc(ratioY * square.startY),
      width: Math.trunc(ratioX * square.w),
      height: Math.trunc(ratioY * square.h),
    }));

    // const storeId = storeIdSquares.map((square) => ({
    //   x: Math.trunc(ratioX * (square.startX - offsetX)),
    //   y: Math.trunc(ratioY * square.startY),
    //   width: Math.trunc(ratioX * square.w),
    //   height: Math.trunc(ratioY * square.h),
    // }));

    const additionalParameters = rects.filter((x) => x.type === ADDITIONAL_PARAM).map((rect) => ({
      mpaFieldName: rect.mpaFieldName,
      mpaKey: rect.mpaKey,
      helpText: rect.mpaFieldName,
      configuration: {
        position: {
          x: Math.trunc(ratioX * (rect.startX - offsetX)),
          y: Math.trunc(ratioY * rect.startY),
          width: Math.trunc(ratioX * rect.w),
          height: Math.trunc(ratioY * rect.h)
        },
        fontColor: additionalParamsColor,
        fontSize: additionalParamsFontSize,
      },
      requiresUserInput: rect.requiresUserInput,
      displayInTemplate: rect.displayInTemplate,
      validation: rect.validations
    }));

    const single_digit_params = new Map();

    rects.filter(x => x.type === SINGLE_DIGIT_TYPE).forEach(obj => {
      if (!single_digit_params.has(obj.mpaFieldName)) single_digit_params.set(obj.mpaFieldName, []);
      single_digit_params.get(obj.mpaFieldName).push(obj);
    })

    if (typeof single_digit_params !== "undefined"){
      single_digit_params.forEach(params => {
        additionalParameters.push({
          mpaFieldName: params[0].mpaFieldName,
          mpaKey: params[0].mpaKey,
          helpText: params[0].mpaFieldName,
          configuration: {
            position: {
              x: Math.trunc(ratioX * (params[0].startX - offsetX)),
              y: Math.trunc(ratioY * params[0].startY),
              width: Math.trunc(ratioX * params[0].w),
              height: Math.trunc(ratioY * params[0].h)
            },
            dynamicPosition: params.map((square) => ({
              x: Math.trunc(ratioX * (square.startX - offsetX)),
              y: Math.trunc(ratioY * square.startY),
              width: Math.trunc(ratioX * square.w),
              height: Math.trunc(ratioY * square.h),
            })),
            fontColor: additionalParamsColor,
            fontSize: additionalParamsFontSize,
          },
          requiresUserInput: params[0].requiresUserInput,
          displayInTemplate: params[0].displayInTemplate,
          validation: params[0].validations
        })
      })
    } 

    // check for store Id addionalparameter
    // TODO remove this once generalized
    // const store_id_rect = rects.find(x => x.type === SINGLE_DIGIT_TYPE)
    // if (typeof store_id_rect !== "undefined"){
    //   additionalParameters.push({
    //     mpaFieldName: store_id_rect.mpaFieldName,
    //     mpaKey: store_id_rect.mpaKey,
    //     helpText: store_id_rect.mpaFieldName,
    //     configuration: {
    //       position: {
    //         x: Math.trunc(ratioX * (store_id_rect.startX - offsetX)),
    //         y: Math.trunc(ratioY * store_id_rect.startY),
    //         width: Math.trunc(ratioX * store_id_rect.w),
    //         height: Math.trunc(ratioY * store_id_rect.h)
    //       },
    //       // dynamicPosition: [
    //       //   {
    //       //     x: Math.trunc(ratioX * (rect.startX - offsetX)),
    //       //     y: Math.trunc(ratioY * rect.startY),
    //       //     width: Math.trunc(ratioX * rect.w),
    //       //     height: Math.trunc(ratioY * rect.h)
    //       //   },
    //       // ],
    //       fontColor: additionalParamsColor,
    //       fontSize: additionalParamsFontSize,
    //     },
    //     requiresUserInput: store_id_rect.requiresUserInput,
    //     displayInTemplate: store_id_rect.displayInTemplate,
    //     validation: store_id_rect.validations
    //   }
    //   )
    // }

    // fields that are marked only as user input required
    if (requiresUserInputOnlyFields.length > 0) {
      const parsed = requiresUserInputOnlyFields.map(x => {
        return {
          mpaFieldName: x.mpaFieldName,
          mpaKey: x.mpaKey,
          helpText: x.mpaFieldName,
          configuration: {
            position: {
              x: 0,
              y: 0,
              width: 0,
              height: 0
            },
            fontColor: additionalParamsColor,
            fontSize: additionalParamsFontSize,
          },
          requiresUserInput: true,
          displayInTemplate: false,
          validation: x.validation,
        }
      })
      additionalParameters.push(...parsed)
    }

    const config = {
      path: filePath,
      configurations: {
        qrCode: {
          x: Math.trunc(actualX),
          y: Math.trunc(actualY),
          width: Math.trunc(actualW),
          height: Math.trunc(actualH),
        },
        creditPartyIdentifier,
        // storeId, 
        cpiFontSize: Math.trunc(cpiFontSize),
        cpiFontColor,
        creditPartyName: cpnPosition,
        cpnFontSize: Math.trunc(cpnFontSize),
        cpnTextAlignment,
        cpnFontColor,
      },
      additionalFields: additionalParameters
    };
    return config;
  };
  const validate = () => {
    let isValidObj = {
      isValid: true,
      error: null,
      header: null,
    };
    if (rects.length === 0) {
      isValidObj.isValid = false;
      isValidObj.header = "Warning";
      isValidObj.error = "Import a pdf file to get started.";
    }
    rects.forEach((rect) => {
      if (rect.startX < 300) {
        isValidObj.isValid = false;
        isValidObj.header = "Warning";
        isValidObj.error = "Make sure all the components are placed on the PDF";
      }
    });
    return isValidObj;
  };

  return (
    <CanvasContext.Provider
      value={{
        canvasRef,
        pdfCanvasRef,
        qrCanvasRef,
        previewCanvasRef,
        leftPaneCanvasRef,
        contextRef,
        initializeEditor,
        changeCPNFontColor,
        changeCPNTextAlignment,
        changeCPIFontColor,
        changeAdditionalFieldsFontColor,
        changeCPNFontSize,
        changeCPIFontSize,
        changeAdditionalFieldsFontSize,
        prepareCanvas,
        startDrawing,
        finishDrawing,
        restCanvas,
        importPDF,
        draw,
        onClick,
        getConfigs,
        validate,
        previewTemplate,
        x,
        y,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvas = () => useContext(CanvasContext);
